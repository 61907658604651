// extracted by mini-css-extract-plugin
export var column = "WhyZenlayer__column__HkVt6";
export var flex = "WhyZenlayer__flex__Ivoq2";
export var flexColumn = "WhyZenlayer__flexColumn__UzcNR";
export var gap1 = "WhyZenlayer__gap1__qzqd2";
export var gap2 = "WhyZenlayer__gap2__E7Z63";
export var gap3 = "WhyZenlayer__gap3__KyhYy";
export var gap4 = "WhyZenlayer__gap4__NFgcq";
export var gap5 = "WhyZenlayer__gap5__a1LUE";
export var root = "WhyZenlayer__root__q2Bt9";
export var row = "WhyZenlayer__row__H_AK_";