// extracted by mini-css-extract-plugin
export var buttonsRow = "BannerSignUpToday__buttonsRow__dmWBk";
export var column = "BannerSignUpToday__column__l_iZo";
export var flex = "BannerSignUpToday__flex__KHjr7";
export var flexColumn = "BannerSignUpToday__flexColumn__b0JR4";
export var gap1 = "BannerSignUpToday__gap1__N4zhM";
export var gap2 = "BannerSignUpToday__gap2__LWcAj";
export var gap3 = "BannerSignUpToday__gap3__IH4Vs";
export var gap4 = "BannerSignUpToday__gap4__dM6yx";
export var gap5 = "BannerSignUpToday__gap5__csSHo";
export var root = "BannerSignUpToday__root__afwEG";
export var row = "BannerSignUpToday__row__m3mQ6";
export var title = "BannerSignUpToday__title__dq1YH";