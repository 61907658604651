// extracted by mini-css-extract-plugin
export var column = "BannerCaseStudy__column__agji8";
export var ctaWrapper = "BannerCaseStudy__ctaWrapper__d0qvS";
export var description = "BannerCaseStudy__description__av86Z";
export var flex = "BannerCaseStudy__flex__wpmJM";
export var flexColumn = "BannerCaseStudy__flexColumn__M4r8j";
export var gap1 = "BannerCaseStudy__gap1__ac9gb";
export var gap2 = "BannerCaseStudy__gap2__TkdCW";
export var gap3 = "BannerCaseStudy__gap3__XaiRf";
export var gap4 = "BannerCaseStudy__gap4__7NBJe";
export var gap5 = "BannerCaseStudy__gap5__GhIya";
export var row = "BannerCaseStudy__row__VyoF4";
export var testimonialText = "BannerCaseStudy__testimonialText__RtnPa";
export var testimonialWrapper = "BannerCaseStudy__testimonialWrapper__xThj7";
export var title = "BannerCaseStudy__title__rpW8Q";
export var wrapper = "BannerCaseStudy__wrapper__TqEBc";
export var wrapperBackgroundImage = "BannerCaseStudy__wrapperBackgroundImage__FE2AL";
export var wrapperContainer = "BannerCaseStudy__wrapperContainer__yzwiA";