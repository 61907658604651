// extracted by mini-css-extract-plugin
export var column = "JumbotronZenlayer__column__snQjL";
export var flex = "JumbotronZenlayer__flex___6DNi";
export var flexColumn = "JumbotronZenlayer__flexColumn__oprtL";
export var gap1 = "JumbotronZenlayer__gap1__YD6sU";
export var gap2 = "JumbotronZenlayer__gap2__sE8pU";
export var gap3 = "JumbotronZenlayer__gap3__vEb10";
export var gap4 = "JumbotronZenlayer__gap4__J9Psi";
export var gap5 = "JumbotronZenlayer__gap5__SoXjx";
export var heading = "JumbotronZenlayer__heading___VV_b";
export var providerIcons = "JumbotronZenlayer__providerIcons__M_Ykz";
export var row = "JumbotronZenlayer__row__sKzep";
export var subheading = "JumbotronZenlayer__subheading__Cxyzg";
export var wrapper = "JumbotronZenlayer__wrapper__aV_OK";