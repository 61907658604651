// extracted by mini-css-extract-plugin
export var cell = "ZenlayerPricing__cell__dZRiV";
export var column = "ZenlayerPricing__column__wKyTI";
export var flex = "ZenlayerPricing__flex__SpEuD";
export var flexColumn = "ZenlayerPricing__flexColumn__B9UAG";
export var gap1 = "ZenlayerPricing__gap1__A96op";
export var gap2 = "ZenlayerPricing__gap2__YF2JV";
export var gap3 = "ZenlayerPricing__gap3__ts2wD";
export var gap4 = "ZenlayerPricing__gap4__wK8Pk";
export var gap5 = "ZenlayerPricing__gap5__GSUB6";
export var heading = "ZenlayerPricing__heading__sUpCD";
export var horizontalScrollRow = "ZenlayerPricing__horizontalScrollRow__VX6gg";
export var large = "ZenlayerPricing__large__FZywW";
export var medium = "ZenlayerPricing__medium__qZHw2";
export var meta = "ZenlayerPricing__meta__TuQ3l";
export var mobileOnlyMeta = "ZenlayerPricing__mobileOnlyMeta__Ci2h9";
export var price = "ZenlayerPricing__price__zXX6O";
export var row = "ZenlayerPricing__row__E4b70";
export var small = "ZenlayerPricing__small__u5ctQ";
export var spacer = "ZenlayerPricing__spacer__HOWoA";
export var title = "ZenlayerPricing__title__LUPhf";
export var wrapper = "ZenlayerPricing__wrapper__DxBBV";